var m = Object.defineProperty;
var p = (s, r, t) => r in s ? m(s, r, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[r] = t;
var a = (s, r, t) => p(s, typeof r != "symbol" ? r + "" : r, t);
import { l as g } from "./chunks/index.js";
import { setLoginFlowState as w, fetcher as f, getUTMParams as u, whenPrerenderingActivated as y, toSlug as d } from "@vgno/utils";
const h = {
  dinepenger: "dp",
  vg: "vg"
};
class T extends HTMLElement {
  constructor() {
    super();
    a(this, "contentId", this.dataset.contentId || "unknown");
    a(this, "contentType", this.dataset.contentType);
    a(this, "deviceType", this.dataset.deviceType);
    a(this, "hasPreRenderedContent", this.hasChildNodes());
    a(this, "newsroom", this.dataset.newsroom || "vg");
    a(this, "stripe", null);
    this.onLoad = this.onLoad.bind(this);
  }
  createLoginUrl(t) {
    var i;
    return (i = window.Identity) == null ? void 0 : i.loginUrl({
      state: w({
        medium: "stripe",
        campaign: t == null ? void 0 : t.campaign,
        term: t == null ? void 0 : t.segmentName,
        content: t == null ? void 0 : t.contentName
      })
    });
  }
  async fetchStripeData() {
    try {
      const t = `https://cm.vg.no/v2/${h[this.newsroom] || "vg"}/stripe`, i = await f(t, { credentials: "include" });
      if (i.status === 204) return;
      if (i.status !== 200)
        throw new Error(`Failed to fetch stripe data: ${i.status}`);
      const e = u(window.location.toString(), !0);
      this.stripe = g.createSalesposter(i, {
        contentProvider: h[this.newsroom] || "vg",
        contentType: this.contentType,
        deviceType: this.deviceType,
        paywallType: "stripe",
        contentId: this.contentId,
        document,
        window,
        utmTagOverrides: e ? {
          utm_campaign: e.campaign,
          utm_content: e.content,
          utm_medium: e.medium,
          utm_source: e.source,
          utm_term: e.terms,
          utm_url: e.url
        } : void 0,
        generateLoginUrl: (c) => this.createLoginUrl(c)
      }), this.onLoad();
    } catch (t) {
      console.error("Failed to generate stripe", t);
    }
  }
  onLoad() {
    if (!this.stripe) return;
    const t = this.stripe.salesposter.html.replace(
      /{{article_id}}/g,
      this.contentId
    );
    this.renderContent(t), this.trackView();
  }
  renderContent(t) {
    var c;
    if (!this.shadowRoot) return console.error("No shadow root found");
    const e = new DOMParser().parseFromString(t, "text/html");
    this.shadowRoot.innerHTML = "", e.querySelectorAll("style").forEach((n) => {
      var o;
      (o = this.shadowRoot) == null || o.appendChild(n.cloneNode(!0));
    }), Array.from(e.body.childNodes).forEach((n) => {
      var o;
      (o = this.shadowRoot) == null || o.appendChild(n.cloneNode(!0));
    }), (c = this.shadowRoot) == null || c.querySelectorAll("script").forEach((n) => {
      var l;
      const o = document.createElement("script");
      n.src ? o.src = n.src : o.textContent = n.textContent, (l = n.parentNode) == null || l.replaceChild(o, n);
    });
  }
  async trackView() {
    var n, o;
    await y();
    const t = window.nmTrackerSync;
    if (!t) {
      console.error(
        "News Media Pulse not initialized. Ensure NM tracker (v7+) is loaded."
      );
      return;
    }
    const i = document.documentElement.dataset.pageType, e = (n = this.stripe) == null ? void 0 : n.salesposter;
    if (!e) return console.error("No stripe data found");
    const c = [
      "salesposter",
      d(e.posterType || "stripe"),
      d(e.contentName || "unknown"),
      e.campaign && d(e.campaign),
      e.segmentName && d(e.segmentName),
      e.abTestGroup && d(e.abTestGroup)
    ].filter(Boolean);
    t.trackViewUIElement({
      origin: (o = this.stripe) == null ? void 0 : o.pulseOrigin,
      object: {
        name: e.contentName || "Salesposter Stripe",
        elementType: e.posterType || "Salesposter - Stripe",
        id: c.join(":"),
        page: {
          id: this.contentId,
          url: window.location.href,
          type: i || "Page"
        },
        placement: { primaryPosition: 1 }
      }
    });
  }
  connectedCallback() {
    let t = null;
    if (this.hasPreRenderedContent)
      for (t = document.createDocumentFragment(); this.firstChild; )
        t.appendChild(this.firstChild);
    this.shadowRoot || (this.attachShadow({ mode: "open" }), t && this.shadowRoot.appendChild(t)), this.fetchStripeData();
  }
}
const S = () => {
  customElements.get("vg-stripe") || customElements.define("vg-stripe", T);
};
export {
  S as default
};
